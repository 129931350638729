//import GrilleDetail from '../components/GrilleDetail';
//let stations = [GrilleDetail] ;
import dataJason from '../data/dataJson.json';


let stations = dataJason

export function getStations() {
    return stations;
  }

export function getStation(nom) {
    return stations.find(
        station => station.nom === nom
    );
  }

export function getRegions() {
    const regionsSet = new Set();
    for (let i = 0; i < dataJason.length; i++) {
      regionsSet.add(JSON.stringify({ region: dataJason[i].region, region_ID: dataJason[i].region_ID }));
    }
    const uniqueRegions = Array.from(regionsSet).map((regionString) => JSON.parse(regionString));
    const region = uniqueRegions.map((region) => ({
      region: region.region,
      region_ID: region.region_ID,
    }));
    return region
};
  
export function getDepartements() {
  const departementSet = new Set();
  for (let i = 0; i < dataJason.length; i++) {
    departementSet.add(JSON.stringify({ departement: dataJason[i].departement, departement_ID: dataJason[i].departement_ID, region: dataJason[i].region, region_ID: dataJason[i].region_ID }));
  }
  const uniqueDepartements = Array.from(departementSet).map((departementString) => JSON.parse(departementString));
  const departement = uniqueDepartements.map((departement) => ({
    departement: departement.departement,
    departement_ID: departement.departement_ID,
    region: departement.region,
    region_ID: departement.region_ID,
  }));
  return departement
};

export function getDomaines() {
  const domaineSet = new Set();
  for (let i = 0; i < dataJason.length; i++) {
    domaineSet.add(JSON.stringify({ domaine: dataJason[i].domaine, domaine_ID: dataJason[i].domaine_ID }));
  }
  const uniqueDomaine = Array.from(domaineSet).map((domaineString) => JSON.parse(domaineString));
  const domaine = uniqueDomaine
    .filter(domaine =>domaine.domaine_ID !== null )
    .map((domaine) => ({
    domaine: domaine.domaine,
    domaine_ID: domaine.domaine_ID,
    // departement: domaine.departement,
    // departement_ID: domaine.departement_ID,
    // region: domaine.region,
    // region_ID: domaine.region_ID,
  }));
  return domaine
};

// version avec departement mais risque de doublons
// Evasion Mont-Blanc et Espace Diamant
// export function getDomaines() {
//   const domaineSet = new Set();
//   for (let i = 0; i < dataJason.length; i++) {
//     domaineSet.add(JSON.stringify({ domaine: dataJason[i].domaine, domaine_ID: dataJason[i].domaine_ID,departement: dataJason[i].departement, departement_ID: dataJason[i].departement_ID, region: dataJason[i].region, region_ID: dataJason[i].region_ID }));
//   }
//   const uniqueDomaine = Array.from(domaineSet).map((domaineString) => JSON.parse(domaineString));
//   const domaine = uniqueDomaine
//     .filter(domaine =>domaine.domaine_ID !== null )
//     .map((domaine) => ({
//     domaine: domaine.domaine,
//     domaine_ID: domaine.domaine_ID,
//     departement: domaine.departement,
//     departement_ID: domaine.departement_ID,
//     region: domaine.region,
//     region_ID: domaine.region_ID,
//   }));
//   return domaine
// };

export function getLabels() {
  const labelsSet = new Set();
  for (let i = 0; i < dataJason.length; i++) {
    labelsSet.add(JSON.stringify({ label_station: dataJason[i].label_station}));
  }
  const uniqueRegions = Array.from(labelsSet).map((labelString) => JSON.parse(labelString));
  const label = uniqueRegions
    .filter(label =>label.label_station !== '' )
    .map((label) => ({
      label_station: label.label_station,
  }));
  return label
};
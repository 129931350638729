import React, { useState, useEffect} from 'react';
import Navbar from '../components/Navbar';
import dataJason from '../data/dataJson.json';
import { getDepartements, getRegions, getDomaines, getLabels, getStations } from '../components/DataJson'
import GrilleDetail from '../components/GrilleDetail';
import { useSearchParams } from 'react-router-dom';
import MultiRangeSlider from "multi-range-slider-react";

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// Penser a bien supprimer toutes les references a la page listestation_copy
// dans apps.js
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------



const regionList = getRegions();
const departementList = getDepartements();
const domaineList = getDomaines();
const labelList = getLabels();
const stationList = getStations();

const links_dynamic = stationList.map(station => ({
   url: `/Decouvrir-Station/${station.nom}`,
   changefreq: 'daily',
   priority: 1.0
 }));
 
 const links_fixed = [
   { url: '/', changefreq: 'daily', priority: 1.0 },
   { url: '/about', changefreq: 'monthly', priority: 0.8 },
   { url: '/comparer-et-trouver-votre-station-de-ski-ideale', changefreq: 'daily', priority: 1.0 },
   ];
 
 const links = [...links_fixed, ...links_dynamic];
   console.log(links)




const Listestation = () => {

   const [searchParams, setSearchParams] = useSearchParams();

   const [selectedRegion, setSelectedRegion] = useState(
      searchParams.get('regions') ? searchParams.get('regions').split(',') : []
    );

   const [selectDepartement, setSelecteDepartement] = useState(
     searchParams.get('departements') ? searchParams.get('departements').split(',') : []
   );
   
   const [selectedDomaine, setSelectedDomaine] = useState(
      searchParams.get('domaines') ? searchParams.get('domaines').split(',') : []
   );   
   
    const [selectedLabel, setSelectedLabel] = useState(
      searchParams.get('labels') ? searchParams.get('labels').split(',') : []
    );

   const [rangeRemontMinValue, setrangeRemontMinValue] = useState(searchParams.get('remontMin') || [1]);
   const [rangeRemontMaxValue, setrangeRemontMaxValue] = useState(searchParams.get('remontMaxValue') || [110]);
   
   const [rangeNbPisteMinValue, setrangeNbPisteMinValue] = useState([1]);
   const [rangeNbPisteMaxValue, setrangeNbPisteMaxValue] = useState([250]);
   const [rangeAltitudeMinValue, setRangeAltitudeMinValue] = useState([500]);
   const [rangeAltidueMaxValue, setRangeAltitudeMaxValue] = useState([3500]);
   
   const [selectedStation, setSelectedStation] = useState(
      searchParams.get('station') ? searchParams.get('station').split(',') : []
);
   

  

 
   useEffect(() => {
     const updatedSelecteDepartements = searchParams.get('departements') ? searchParams.get('departements').split(',') : [];
     const selectDepartementAsIntegers = updatedSelecteDepartements.map((id) => Number(id));
     setSelecteDepartement(selectDepartementAsIntegers) ; 
     
     const updatedSelecteRegions = searchParams.get('regions') ? searchParams.get('regions').split(',') : [];
     const selectRegionAsIntegers = updatedSelecteRegions.map((id) => Number(id));
     setSelectedRegion(selectRegionAsIntegers) ; 

     setSelectedDomaine (searchParams.get('domaines') ? searchParams.get('domaines').split(',') : []);
     setSelectedStation (searchParams.get('stations') ? searchParams.get('stations').split(',') : []);
     
     setSelectedLabel (searchParams.get('labels') ? searchParams.get('labels').split(',') : []);
     
     setrangeRemontMinValue (searchParams.get('remontMin') ? searchParams.get('remontMin') : [1]);
     setrangeRemontMaxValue (searchParams.get('remontMax') ? searchParams.get('remontMax') : [110]);
     
     setrangeNbPisteMinValue (searchParams.get('nbpisteMin') ? searchParams.get('nbpisteMin') : [1]);
     setrangeNbPisteMaxValue (searchParams.get('nbpisteMax') ? searchParams.get('nbpisteMax') : [250]);
     setRangeAltitudeMinValue (searchParams.get('altidudeMin') ? searchParams.get('altidudeMin') : [500]);
     setRangeAltitudeMaxValue (searchParams.get('altitudeMax') ? searchParams.get('altitudeMax') : [3500]);

   }, [searchParams]);
 
   
   const filterStations = dataJason
      .filter((station) => {
      if (selectedRegion.length === 0) {
         return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
      } else {
         return selectedRegion.some((id) => station.region_ID === id);
      }
      })
      .filter((station) => {
         if (selectDepartement.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectDepartement.some((id) => station.departement_ID === id);
         }
      })
      .filter((station) => {
         if (selectDepartement.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectDepartement.some((id) => station.departement_ID === id);
         }
      })
      .filter((station) => {
         if (selectedDomaine.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectedDomaine.includes(station.domaine);
         }
      })
      .filter((station) => {
         if (selectedStation.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectedStation.includes(station.nom);
         }
      })
      .filter((station) => {
         if (selectedLabel.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectedLabel.includes(station.label_station);
         }
      }) 
      .filter((station) => station.nb_remontee_meca >= rangeRemontMinValue)
      .filter((station) => station.nb_remontee_meca <= rangeRemontMaxValue)
      .filter((station) => station.nb_piste >= rangeNbPisteMinValue)
      .filter((station) => station.nb_piste <= rangeNbPisteMaxValue)
      .filter((station) => station.hauteur_min >= rangeAltitudeMinValue)
      .filter((station) => station.hauteur_max <= rangeAltidueMaxValue)
   ;
   

   const allFiltersSelected = selectedRegion.length + selectDepartement.length + selectedDomaine.length + selectedLabel.length + selectedStation.length
  

   const handleRegionChange = (eventRegion) => {
      const region_ID = parseInt(eventRegion.target.id);
      const newSelecteRegion = selectedRegion.includes(region_ID)
      ? selectedRegion.filter((r) => r !== region_ID)
      : [...selectedRegion, region_ID];
      
      const newParams = new URLSearchParams(searchParams);
      if (newSelecteRegion.length > 0) {
        newParams.set('regions', newSelecteRegion.join(','));
        
      } else {
        newParams.delete('regions');
      }
      setSearchParams(newParams);
    };
   
    const handleDepartementChange = (eventDepartement) => {
      const departement_ID = parseInt(eventDepartement.target.id);
      const newSelecteddepartements = selectDepartement.includes(departement_ID)
      ? selectDepartement.filter((r) => r !== departement_ID)
      : [...selectDepartement, departement_ID];
      
      const newParams = new URLSearchParams(searchParams);
      if (newSelecteddepartements.length > 0) {
        newParams.set('departements', newSelecteddepartements.join(','));
        
      } else {
        newParams.delete('departements');
      }
      setSearchParams(newParams);
    };
   
    const handleDomaineChange = (domaine) => {
      const newSelectedDomaine = selectedDomaine.includes(domaine)
      ? selectedDomaine.filter((r) => r !== domaine)
      : [...selectedDomaine, domaine];

      const newParams = new URLSearchParams(searchParams);
      if (newSelectedDomaine.length > 0) {
        newParams.set('domaines', newSelectedDomaine.join(','));
        
      } else {
        newParams.delete('domaines');
      }
      setSearchParams(newParams);
    };

    const handleStationChange = (station) => {
      const newSelectedStation = selectedStation.includes(station)
      ? selectedStation.filter((r) => r !== station)
      : [...selectedStation, station];

      const newParams = new URLSearchParams(searchParams);
      if (newSelectedStation.length > 0) {
        newParams.set('stations', newSelectedStation.join(','));

      } else {
        newParams.delete('stations');
      }
      setSearchParams(newParams);
    };

    const handleLabelChange = (label) => {
      const newselectedLabel = selectedLabel.includes(label)
      ? selectedLabel.filter((r) => r !== label)
      : [...selectedLabel, label];

      const newParams = new URLSearchParams(searchParams);
      if (newselectedLabel.length > 0) {
        newParams.set('labels', newselectedLabel.join(','));
        
      } else {
        newParams.delete('labels');
      }
      setSearchParams(newParams);
    };

    const handleRangeChange = (range, valueMin, valueMax) => {
      const newParams = new URLSearchParams(searchParams);     
      if (range ==='remont') {
         if (valueMin !== 1)  {newParams.set('remontMin', valueMin);}
         else { newParams.delete('remontMin');} 
      
         if (valueMax !== 110)  {newParams.set('remontMax', valueMax);}
         else { newParams.delete('remontMax');}
         }      
      else if (range ==='altitude') {
         if (valueMin !== 500)  {newParams.set('altidudeMin', valueMin);}
         else { newParams.delete('altidudeMin');} 
      
         if (valueMax !== 3500)  {newParams.set('altitudeMax', valueMax);}
         else { newParams.delete('altitudeMax');}
         }      
      else if (range ==='nbpiste') {
         if (valueMin !== 1)  {newParams.set('nbpisteMin', valueMin);}
         else { newParams.delete('nbpisteMin');} 
      
         if (valueMax !== 250)  {newParams.set('nbpisteMax', valueMax);}
         else { newParams.delete('nbpisteMax');}
         }      
      
      setSearchParams(newParams);
    };

   return (
      <div className='listestation'>

         <header className='header'>
            <div className='background'>
               <Navbar />
               <div className="textbox">
                  <h1>Comparer et Trouver Votre Station de Ski Idéale</h1>
               </div>
            </div>
         </header>
         <section className="section-list-station">
         <div className='detail_lststation'>
            <div className='entePage'>
               <h2>Liste des Stations</h2>
            </div>

            <div className='grilleStation'>
               <div className='FiltreStation'>

                  <h3>Filtrer par</h3>
                  <div className='filterType'>
                  <span> {allFiltersSelected > 0 && (<button onClick={() => setSearchParams()}>Annuler filtre</button>)}</span>
                     <div className='headFilter'>
                        <span><h4>Région</h4></span>
                     </div>
                     <ul className="region-container">
                        {regionList.sort((a, b) => (a.region < b.region ? -1 : 1))
                           .map(region => (
                              <li key={region.region_ID}>
                                 <input
                                    type="checkbox"
                                    name="region"
                                    id={region.region_ID}
                                    checked={selectedRegion.includes(region.region_ID)}
                                    onChange={handleRegionChange}
                                 />
                                 <label htmlFor={region.region_ID}>{region.region} </label>
                              </li>
                           ))}
                     </ul>
                  </div>
                  <details className='filterType'>
                     <summary className='headFilter'>
                        <span><h4>Département</h4></span>
                     </summary>
                     <div className='liste-departement'  >
                        <ul className="departement-container">
                           {departementList.sort((a, b) => (a.departement < b.departement ? -1 : 1))
                               .map(departement => (
                                 <li key={departement.departement_ID}>
                                    <input
                                       type="checkbox"
                                       name="departement"
                                       id={departement.departement_ID}
                                      checked={selectDepartement.includes(departement.departement_ID)}
                                      onChange={handleDepartementChange}
                                    />
                                    <label htmlFor={departement.departement_ID}>{departement.departement}</label>
                                 </li>
                              ))}
                        </ul>
                     </div>
                  </details>
                  <details className='filterType'>
                     <summary className='headFilter'>
                     <span><h4>Domaine</h4></span>
                     </summary>
                     <div className='liste-domaine'  >
                        <ul className="domaine-container">
                           {domaineList.sort((a, b) => (a.domaine < b.domaine ? -1 : 1))
                              .map(domaine => (
                                 <li key={domaine.domaine}>
                                    <input
                                       type="checkbox"
                                       name="domaine"
                                       id={domaine.domaine}
                                       checked={selectedDomaine.includes(domaine.domaine)}
                                          onChange={() => handleDomaineChange(domaine.domaine)}
                                    />
                                    <label htmlFor={domaine.domaine_ID}>{domaine.domaine}</label>
                                 </li>
                              ))}
                        </ul>
                     </div>
                  </details>

                  <div className='filterType'>

               <div className='headFilter'>
                  <span><h4>Station</h4></span>
               </div>
               <ul className="station-container">
                           {stationList.sort((a, b) => (a.nom < b.nom ? -1 : 1))
                              .map(station => (
                                 <li key={station.nom}>
                                    <input
                                       type="checkbox"
                                       name="nom"
                                       id={station.nom}
                                       checked={selectedStation.includes(station.nom)}
                                          onChange={() => handleStationChange(station.nom)}
                                    />
                                    <label htmlFor={station.nom}>{station.nom}</label>
                                 </li>
                              ))}
                  </ul>
               </div>
               
                  <details className='filterType'>
                     <summary className='headFilter'>
                     <span><h4>Label</h4></span>
                     </summary>
                     <div className='liste-label'  >
                        <ul className="label-container">
                           {labelList.sort((a, b) => (a.label_station < b.label_station ? -1 : 1))
                              .map(label => (
                                 <li key={label.label_station}>
                                    <input
                                       type="checkbox"
                                       name="label"
                                       id={label.label_station}
                                       checked={selectedLabel.includes(label.label_station)}
                                          onChange={() => handleLabelChange(label.label_station)}
                                    />
                                    <label htmlFor={label.label_station}>{label.label_station}</label>
                                 </li>
                              ))}
                        </ul>
                     </div>
                  </details>

                  <details className='groupe_Range'>
                     <summary className='headFilter'>
                        <span><h4>Altitude</h4></span><br />
                     </summary>
                     <div className='rangeAltitude'>
                        <MultiRangeSlider
                           step="50"
                           min="500"
                           max="3500"
                           ruler="false"
                           minValue={rangeAltitudeMinValue}
                           maxValue={rangeAltidueMaxValue}
                           onChange={(e) => {
                              handleRangeChange('altitude',e.minValue,e.maxValue);
                           }}
                        ></MultiRangeSlider>
                        <p>Entre {rangeAltitudeMinValue} m et {rangeAltidueMaxValue} m</p>

                     </div>
                  </details>

                  <details className='groupe_Range'>
                     <summary className='headFilter'>
                        <span><h4>Nombre de piste</h4></span>
                     </summary>
                     <div className='rangeNbPisteMinValue'>
                        <MultiRangeSlider
                           min="1"
                           max="250"
                           ruler="false"
                           minValue={rangeNbPisteMinValue}
                           maxValue={rangeNbPisteMaxValue}
                           onChange={(e) => {
                              handleRangeChange('nbpiste',e.minValue,e.maxValue);
                           }}
                        ></MultiRangeSlider>
                        <p>Entre {rangeNbPisteMinValue} et {rangeNbPisteMaxValue} pistes</p>
                     </div>
                  </details>


                  <details className='groupe_Range'>
                     <summary className='headFilter'>
                        <span><h4>Remontée mécanique</h4></span>
                     </summary>

                     <div className='rangeNbRemonte'>
                        <MultiRangeSlider
                           ruler="false"
                           min="1"
                           max="110"
                           minValue={rangeRemontMinValue}
                           maxValue={rangeRemontMaxValue}
                           onChange={(e) => {
                              handleRangeChange('remont',e.minValue,e.maxValue);
                           }}
                        ></MultiRangeSlider>
                        <p>
                           Entre {rangeRemontMinValue} et {rangeRemontMaxValue} remontées
                        </p>
                     </div>
                  </details>

               </div>
               <div className='listeStationGrid'>
                  {filterStations
                     .sort((a, b) => (a.nom < b.nom ? -1 : 1))
                     .map((station) => (
                        <GrilleDetail station={station} key={station.id} />

                     ))}
               </div>
            </div>
         </div>
         </section>
      </div>

   );


};

export default Listestation;
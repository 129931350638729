import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./styles/index.scss";

// <BrowserRouter basename={process.env.PUBLIC_URL}>

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
      
    <HelmetProvider>
      <App />
     </HelmetProvider>  
     </BrowserRouter>  
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from './../assets/logo.png';
import btn from './../assets/icon/menu.svg'
import close from './../assets/icon/cross.svg'


export default function Navbar() {

  //change nav color when scrolling
  const [color, setColor] = useState(false)
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true)
    }
    else {
      setColor(false)
    }
  }

  window.addEventListener('scroll', changeColor)

  //animate burger menu
  const [isActive, setIsActive] = useState(false);

  return (
    <nav className={color ? 'nav nav-bg' : 'nav'}>
      <div className='sous-nav'>

        <Link to="/" title='Accueil Mon Chamois'><img src={Logo} alt='logo site Mon Chamois avec un dessin de chamois'></img></Link>
        <button onClick={() => setIsActive(true)} className='menu-btn'>
          <img src={btn} alt=''></img>
        </button>
        <div className={`nav-links ${isActive ? "active" : ""}`}>
        <button onClick={() => setIsActive(false)} className='menu-btn'>
          <img src={close} alt=''></img>
        </button>
          <ul>
            <li> <Link  onClick={() => setIsActive(false)} to="/"> Accueil</Link></li>
            <li> <Link onClick={() => setIsActive(false)} to="/comparer-et-trouver-votre-station-de-ski-ideale"> Comparer</Link></li>
            <li> <Link onClick={() => setIsActive(false)} to="/About">Nous connaître</Link></li>
          </ul>
        </div>
      </div>
    </nav>

  )
} 
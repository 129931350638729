import React from 'react';
import Navbar from '../components/Navbar'
import { useParams, Link } from "react-router-dom";
import { getStation } from '../components/DataJson'
import icon_map from './../assets/icon/icon_map.png'
import icon_site from './../assets/icon/icon_site.png'
import icon_webcam from './../assets/icon/webcam.png'
import icon_flag from './../assets/icon/flag.png'
import { Helmet } from 'react-helmet-async';
import SkiStationMap from '../components/StationMap';


export default function Detailstation() {
    let params = useParams();
    //let station = getStation(parseInt(params.id, 10));
    let station = getStation(params.nom);

    let cannonicalLink = "/Decouvrir-Station/" && station.nom;
    let metaDescription = "Toutes les informations pour savoir si " && station.nom && " est la station de vos rêves";

  // const address = station.adresse;

    return <div className='detailstation'>
        <Helmet>
                <title>{station.nom} : venez découvir la station </title>
                <meta name="description" content={metaDescription}></meta>
            <link rel="canonical" href={cannonicalLink}></link>
         </Helmet>

        <header className='header'>
            <div className='background'>
                <Navbar />
                <div className='textbox'>
                    <h1>{station.nom}</h1>
                    <p>Découvrez toutes les informations relative à la station
                    </p>
                </div>
            </div>
        </header>

        <section className="section-detail_station">
        <div className="bloc-detail-station">
            <div className='top_class'>
                <h2>Information Station</h2>
            </div>
            <div className='det_info_station'>
            

                <div className="det_stat_info">
                    <div className="det_stat_ss_element">
                        <div className='det_stat_ss_top'>
                            <h3>Localisation</h3>
                        </div>
                        <div className='det_stat_ss_block'>
                        <ul>
                            <li><div className="internalLink"><Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=${station.region_ID}`} key={station.region}>{station.region}</Link></div> </li>
                            <li><div className="internalLink"><Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?departements=${station.departement_ID}`} key={station.departement}>{station.departement}</Link></div> </li>
                        </ul>
                        </div>
                    </div>
                    <div className="det_stat_ss_element">
                        
                        <div className='det_stat_ss_top'>
                            <h3>Office de tourisme</h3>
                        </div>
                        <div className='det_stat_ss_block'></div>
                        <ul>
                            <li>{station.adresse}</li>
                            <li>{station.telephone}</li>
                        </ul>
                    </div>
                    <div className="det_stat_ss_element">
                        <div className='det_stat_ss_top'>
                            <h3>Site</h3>
                        </div>
                        <div className='det_stat_ss_block'>                                
                            <a href={station.url} target="_blank" rel="noreferrer">
                                <img src={icon_site} alt='Lien vers site de la station' className='icon_lien' ></img>
                            </a>
                        </div>
                    </div>
                    
                </div>
                <SkiStationMap address={station.adresse} name={station.nom} />
            </div>
            <div className='top_class'>
                <h2>Domaine Ski Alpin</h2>
            </div>
            <div className='det_info_domaine'>
                <div className="det_stat_info">
                    <div className="det_stat_ss_element">
                        <div className='det_stat_ss_top'></div>
                        <h3>Hauteur domaine</h3>
                        <div className='det_stat_ss_block'></div>
                        <ul>
                            <li>Hauteur bas : {station.hauteur_min} </li>
                            <li>Hauteur haut : {station.hauteur_max} </li>
                            <li>Dénivelé de {station.hauteur_max - station.hauteur_min} mètres</li>
                        </ul>
                    </div>
                    <div className="det_stat_ss_element">
                        <div className='det_stat_ss_top'></div>
                        <h3>{station.nb_piste} pistes</h3>
                        <div className='det_stat_ss_block'>
                        <ul className='list_flag_track'>
                            <li><img src={icon_flag} alt='drapeau vert' className='icon_flag_green' ></img>
                                {station.nb_piste_verte} pistes vertes</li>
                            <li><img src={icon_flag} alt='drapeau bleu' className='icon_flag_blue' ></img>
                                {station.nb_piste_bleue} pistes bleues</li>
                            <li><img src={icon_flag} alt='drapeau rouge' className='icon_flag_red' ></img>
                                {station.nb_piste_rouge} pistes rouges</li>
                            <li><img src={icon_flag} alt='drapeau noir' className='icon_flag_black' ></img>
                                {station.nb_piste_noire} pistes noires</li>

                        </ul>
                        </div>
                    </div>
                    <div className="det_stat_ss_element">
                        <div className='det_stat_ss_top'>
                            <h3>{station.nb_remontee_meca} remontées mécaniques</h3>
                        </div>
                        <div className='det_stat_ss_block'>
                            <ul>
                                <li>téléphérique : {station.nb_remontee_telepherique}</li>
                                <li>télécabine : {station.nb_remontee_telecabine}</li>
                                <li>télésiège : {station.nb_remontee_telesiege} </li>
                                <li>téléski : {station.nb_remontee_teleski} </li>
                            </ul>
                        </div>
                    </div>
                    <div className="det_stat_ss_element">
                        <div className='det_stat_ss_top'>
                            <h3>Plan des pistes</h3>
                        </div>
                        <div className='det_stat_ss_block'>
                            <a href={station.url_plan_piste} target="_blank" rel="noreferrer">
                                <img src={icon_map} alt='Lien vers plan des pistes' className='icon_lien' ></img>
                            </a>
                        </div>
                    </div>
                    <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Webcam</h3>
                                </div>
                              <div className='det_stat_ss_block'>
                                    <a href={station.url_webcam} target="_blank" rel="noreferrer">
                                    <img src={icon_webcam} alt='Lien vers webcam' className='icon_lien' ></img>
                                    </a>
                              </div>
                   </div>
                </div>
            </div>

            {station.domaine &&
                <div className='det_label'>
                    <div className='top_class'>
                        <h2>Domaine skiable reliés {station.domaine_liaison}</h2>
                    </div>
                    <div className='det_info_label'>
                        <div className="det_stat_info">
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'></div>
                                <h3>Domaine</h3>
                                <div className='det_stat_ss_block'></div>
                                <ul>
                                    <li><div className="internalLink"><Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?domaines=${station.domaine}`} key={station.domaine}>{station.domaine}</Link></div> </li>
                                </ul>
                            </div>
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Nombre de piste</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        <li>{station.domaine_nb_km_piste}</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }

            {station.label_station &&
                <div className='det_label'>
                    <div className='top_class'>
                        <div className='det_stat_ss_top'></div>
                        <h2>Label</h2>
                    </div>
                    <div className='det_info_label'>
                        <div className="det_stat_info">
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Famille Plus</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        <li>
                                            <i>
                                                <img src="https://s2.qwant.com/thumbr/0x380/d/e/f2b6038bcb1fa9635bfb19286372a7c3e4c142b9e7c1fee8661eee099a3a2a/Logo_LABEL_FamillePlus_RVB_2012.jpg?u=https%3A%2F%2Fwww.anmsm.fr%2Fsites%2Fdefault%2Ffiles%2Finline-images%2FLogo_LABEL_FamillePlus_RVB_2012.jpg&q=0&b=1&p=0&a=0" alt='logo association famille plus' />
                                            </i>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
        </section>
    </div>
}

import React from 'react';
import {Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Listestation from "./pages/Listestation";
import ListestationCopy from "./pages/Listestation_copy";
import Detailstation from "./pages/Detailstation";
import About from "./pages/About";
import NotFound from "./pages/NotFound";


const App = () => {
  return (
  
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/comparer2" element={<ListestationCopy />} ></Route>
        <Route path="/comparer-et-trouver-votre-station-de-ski-ideale" element={<Listestation />} >
          <Route path=":nom" element={<Detailstation />} />
        </Route>
        <Route path="/Decouvrir-Station/:nom" element={<Detailstation />} />
        <Route path="/About" element={<About />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>

    
  );
};

export default App;
